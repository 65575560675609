























































































import {Component, Vue} from "vue-property-decorator";
import BankStatementDTO from "@/dto/BankStatementDTO";
import {namespace} from "vuex-class";
import BankStatementsFilter from "@/dto/BankStatementsFilter";
import BankStatementsModal from "@/components/profile/bank/BankStatementsModal.vue";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import UserDTO from "@/dto/UserDTO";
import RouteNames from "@/router/RouteNames";
import Workspaces from "@/state/Workspaces";
import CreateCBSR from "@/components/request/cbsr/CreateCBSR.vue";
import {Period} from "@/constants/DateTimeConstants";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class BankStatements extends Vue{

    private years: Array<string> = [];

    private filter = new BankStatementsFilter();

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    @Auth.State
    private user!: UserDTO

    private message = "";

    private successful = false;
    private bankStatements: Array<BankStatementDTO> = [];

    mounted() {
      this.loadAllStatementsYears().then(() => this.loadBankStatements());
    }


    loadAllStatementsYears() {
        this.startLoading();
        this.message = "";
        this.successful = false;
        return  CompanyService.getAllStatementsYears(Workspaces.getCurrent.id).then(
            res => {
                this.years = res.data;
                this.filter.year = this.years[0];
                this.successful = true;
                this.stopLoading();
            },
            err => this.errorHandle(err)
        )
    }

    errorHandle(error: any) {
        this.successful = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.stopLoading()
    }

    getPeriod(period: Period) {
        return this.getMonth(period);
    }

    getMonth(period: Period) {
        switch (period) {
            case Period.JANUARY:
                return this.$t("period.month.jan");
            case Period.FEBRUARY:
                return this.$t("period.month.feb");
            case Period.MARCH:
                return this.$t("period.month.mar");
            case Period.APRIL:
                return this.$t("period.month.apr");
            case Period.MAY:
                return this.$t("period.month.may");
            case Period.JUNE:
                return this.$t("period.month.jun");
            case Period.JULY:
                return this.$t("period.month.jul");
            case Period.AUGUST:
                return this.$t("period.month.aug");
            case Period.SEPTEMBER:
                return this.$t("period.month.sep");
            case Period.OCTOBER:
                return this.$t("period.month.oct");
            case Period.NOVEMBER:
                return this.$t("period.month.nov");
            case Period.DECEMBER:
                return this.$t("period.month.dev");
        }
    }

    openModal() {
        this.$modal.show(
            BankStatementsModal,
            {
                onSave: (year : string) => this.loadAllStatementsYears().then(() => this.loadBankStatements(year)),
                userId: Workspaces.getCurrent.userId
            },
            {
                width: "50%",
                height: "auto",
                scrollable: true,
            }
        );
    }

    loadBankStatements(year = "") {
        this.startLoading();
        this.message = "";
        if (year) {
            this.filter.year = year;
        }
        this.filter.companyId = Workspaces.getCurrent.id;
        CompanyService.getAllBankStatementsByActualYear(this.filter).then(
            res => {
                this.bankStatements = res.data;
                this.stopLoading()
            },
            err => this.errorHandle(err)
        )
    }

  openRequestStatementsModal() {
    this.$router.push({name: RouteNames.REQUESTS});
  }
}
